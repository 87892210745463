import moment from 'moment';
import TimeField from 'react-simple-timefield';
import React, { useRef } from 'react';
import MaskedInput from 'antd-mask-input'
import  ReactToPrint   from 'react-to-print';
import { Typography, Upload, message, Row, Col, InputNumber, Space, Empty, Collapse, Alert, Tooltip, Card, Table, Divider, Form, Input, Select, Modal, Button, DatePicker, Tabs, List, Popconfirm, Descriptions } from 'antd';
import { FileSearchOutlined, StopOutlined, SearchOutlined, InfoCircleOutlined, CheckOutlined, IdcardOutlined, UploadOutlined, PlusOutlined, CloseOutlined, PrinterOutlined, CalendarOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';

import Client_View from '../../img/icons/client_view.png';
import Avatar from 'antd/lib/avatar/avatar';

moment.locale('ru');

const { Dragger } = Upload;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Meta } = Card;
const { Panel } = Collapse;
const { Option, OptGroup } = Select;
const { Paragraph, Link, Title, Text } = Typography;

const Global = props => {

    let { filteredInfo } = props.state;
    filteredInfo = filteredInfo || {};

    const [formsearch] = Form.useForm();
    const [formsearchcard] = Form.useForm();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const [form5] = Form.useForm();
    const [form6] = Form.useForm();
    const [form7] = Form.useForm();
    const [form8] = Form.useForm();
    const [form9] = Form.useForm();
    const [form10] = Form.useForm();
    const [form11] = Form.useForm();
    const [form12] = Form.useForm();
    const [form13] = Form.useForm();
    const [form14] = Form.useForm();
    const [form15] = Form.useForm();
    const [form16] = Form.useForm();
    const [form17] = Form.useForm();

    const [docsearch, setDocsearch] = React.useState('');
    const [cardsearch, setCardsearch] = React.useState('');
    const [docload, setDocload] = React.useState(0);

    formsearch.setFieldsValue({ search: docsearch });
    formsearchcard.setFieldsValue({ search: cardsearch });
    form2.setFieldsValue({ search: props.state.search });


    const componentRef = useRef();
    const componentRef2 = useRef();
    const componentRef3 = useRef();
    const componentRef4 = useRef();

    var obj = {
        //* Поиск
        get clientlist () {
            if ( props.state.clients.length > 0 ) {
                let search = props.state.search.toLowerCase();
                return props.state.clients.filter( function( item ) {
                    return(
                        item['phone'].toLowerCase().indexOf( search ) > -1 ||
                        item['lastname'].toLowerCase().indexOf( search ) > -1 ||
                        item['firstname'].toLowerCase().indexOf( search ) > -1 ||
                        item['patronymic'].toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return props.state.clients;
            }
        },
        get doclist () {
            if ( props.state.docs.length > 0 ) {
                let search = docsearch.toLowerCase();
                return props.state.docs.filter( function( item ) {
                    return(
                        item['file'].toLowerCase().indexOf( search ) > -1 ||
                        item['text'].toLowerCase().indexOf( search ) > -1 ||
                        item['record'].toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return props.state.docs;
            }
        },
        get cardlist () {
            if ( props.state.cards.length > 0 ) {
                let search = cardsearch.toLowerCase();
                return props.state.cards.filter( function( item ) {
                    return(
                        item['doctor_name'].toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return props.state.cards;
            }
        },
        get pers () {
            return props.state.pers.filter( function( item ) {
                return(
                    item['visible'] === '1'
                );
            } );
        }
    }

    const columns1 = [
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            render: (text) => <Link href={`tel:+${text}`}>{`+${text}`}</Link>,
            width: 100,
        },
        {
            title: 'ФИО',
            width: 100,
            render: (record) => (`${record.lastname.length > 0 ? record.lastname:''}${record.firstname.length > 0 ? ' '+record.firstname:'' }${record.patronymic.length > 0 ? ' '+record.patronymic:''}`)
        },
        {
            title: 'Бонусы',
            dataIndex: 'cashback',
            key: 'cashback',
            render: (text) => text,
            width: 100,
        }
    ];

    const columns2 = [
        {
            title: 'Файл',
            dataIndex: 'file',
            key: 'file',
            width: 100,
            render: (text) => <Link href={`${props.state.api}uploads/${text}`} target="_blank">{text}</Link>
        },
        {
            title: 'Название',
            key: 'text',
            render: (record) => 
                <Paragraph editable={{ tooltip: false, onChange: (e) => doc_upd({'method':'doc_upd','docid':record.id,'text':e},{'method':'patient_docs','patid':props.state.patientinfo.profile.id}) }}>
                    {record.text}
                </Paragraph>,
            width: 100,
        },
        {
            title: 'Тип',
            dataIndex: 'type_text',
            key: 'type_text',
            width: 100,
            filters: obj.doclist.map(function(item){return {text:item.type_text,value:item.type_text}}).map(JSON.stringify).reverse().filter(function (e, i, a) {return a.indexOf(e, i+1) === -1;}).reverse().map(JSON.parse),
            filteredValue: filteredInfo.type_text || null,
            onFilter: (value, record) => record.type_text.includes(value),
            sorter: (a, b) => a.type_text.localeCompare(b.type_text),
        },{
            title: 'Прием',
            dataIndex: 'record',
            key: 'record',
            width: 100,
            render: (value, record) => record.record !== "0" ? <Link onClick={() => recordpushview(record.record)}>Прием № {record.record}</Link> : "-",
            filters: obj.doclist.map(function(item){return {text:item.record !== "0" ? `Прием №${item.record}` : "-",value:item.record}}).map(JSON.stringify).reverse().filter(function (e, i, a) {return a.indexOf(e, i+1) === -1;}).reverse().map(JSON.parse),
            filteredValue: filteredInfo.record || null,
            onFilter: (value, record) => record.record.includes(value),
            sorter: (a, b) => a.record.localeCompare(b.record),
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => props.lasttime(text,'Неизвестно когда','last'),
            width: 100,
            sorter: (a, b) => a.date.localeCompare(b.date),
        },{
            title: 'Действия',
            key: 'action',
            render: (record) => (
                <Space wrap key={record.id} size="middle">
                    <Popconfirm placement="topRight" onConfirm={() => doc_del({ 'method':'doc_del', 'docid':`${record.id}` },{'method':'patient_docs','patid':props.state.patientinfo.profile.id})} title={`Вы действительно хотите удалить «${record.file}»?`} okText="Да" cancelText="Нет">
                        <Button danger shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
            width: 100,
          }
    ];

    const columns3 = [
        {
            title: 'Файл',
            dataIndex: 'file',
            key: 'file',
            width: 100,
            render: (text) => <Link href={`${props.state.api}uploads/${text}`} target="_blank">{text}</Link>
        },
        {
            title: 'Название',
            key: 'text',
            render: (record) => 
                <Paragraph editable={{ tooltip: false, onChange: (e) => doc_upd({'method':'doc_upd','docid':record.id,'text':e},{'method':'record_docs','recid':props.state.recordinfo.data.id}) }}>
                    {record.text}
                </Paragraph>,
            width: 100,
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => props.lasttime(text,'Неизвестно когда','last'),
            width: 100,
            sorter: (a, b) => a.date.localeCompare(b.date),
        },{
            title: 'Действия',
            key: 'action',
            render: (record) => (
                <Space wrap key={record.id} size="middle">
                    <Popconfirm placement="topRight" onConfirm={() => doc_del({ 'method':'doc_del', 'docid':`${record.id}` },{'method':'record_docs','recid':props.state.recordinfo.data.id})} title={`Вы действительно хотите удалить «${record.file}»?`} okText="Да" cancelText="Нет">
                        <Button danger shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
            width: 100,
          }
    ];

    const columns4 = [
        {
            title: 'Доктор',
            dataIndex: 'doctor_name',
            key: 'doctor_name',
            render: (text) => text,
            width: 100,
        },{
            title: 'Дата визита',
            dataIndex: 'date',
            key: 'date',
            render: (text) => props.lasttime(text,'Неизвестно когда','last'),
            width: 100,
            sorter: (a, b) => a.date.localeCompare(b.date),
        },{
            title: 'Действия',
            key: 'action',
            render: (record) => (
                <Space wrap key={record.id} size="middle">
                    <Button shape="circle" icon={<EyeOutlined />} onClick={() => {props.panel('patient_cards_view');cardspushview(record.id)}} />
                    <Popconfirm placement="topRight" onConfirm={() => doc_del({ 'method':'patient_cards_del', 'cardid':`${record.id}` },{'method':'patient_cards','patid':props.state.patientinfo.profile.id})} title={`Вы действительно хотите удалить данную карту?`} okText="Да" cancelText="Нет">
                        <Button danger shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
            width: 100,
          }
    ];

    async function doc_upd (v1,v2) {
        await props.api(v1,false);
        await props.api(v2,false);
    }

    async function doc_del (v1,v2) {
        await props.api(v1,false);
        await props.api(v2,false);
    }

    //* Текст поиска
    function onSearch (e) { 
        setDocsearch(e.target.value.replace(/\s+/g,' ')); 
    }

    //* Текст поиска
    function onCards (e) { 
        setCardsearch(e.target.value.replace(/\s+/g,' ')); 
    }
    
    //* Отправка формы клиенты
    async function cliadd(v) {
        v['method'] = 'client_add';
        v['birthday'] = Date.parse(v['birthday'])/1000;
        v['phone'] = Number(('7'+v['phone']).replace(/\D+/g,""));
        if (String(v['phone'])[1] !== '9') {
            props.notif('error','Неверный номер','Номер должен начинаться на 79');
            return false;
        }
        if (String(v['phone']).length !== 11) {
            props.notif('error','Неверный номер','Длина номера должна быть равна 11 символам');
            return false;
        }
        await props.api(v,true);
    }

    //* Отправка формы клиенты
    async function patadd(v) {
        v['method'] = 'patient_add';
        await props.api(v,true);
    }

    //* Отправка формы
    async function notifadd(v) {
        v['method'] = 'client_notif_add';
        v['date'] = Date.parse(props.fulltime(v['date'],v['datet']))/1000;
        await props.api(v,true);
    }

    async function clipush(id) {
        await form1.resetFields();
        await props.api({
            'method':'client_get',
            'cliid':id
        },false);
        await props.panel('client_view');
    }

    //* Отправка формы клиента
    async function clientupd(v) {
        v['method'] = 'client_upd';
        v['birthday'] = Date.parse(v['birthday'])/1000;
        v['phone'] = Number(('7'+v['phone']).replace(/\D+/g,""));
        if (String(v['phone'])[1] !== '9') {
            props.notif('error','Неверный номер','Номер должен начинаться на 79');
            return false;
        }
        if (String(v['phone']).length !== 11) {
            props.notif('error','Неверный номер','Длина номера должна быть равна 11 символам');
            return false;
        }
        await props.api(v,true);
    }

    //* Вызов окна добавления
    async function clipushadd() {
        await props.modal('cli_add');
    }

    //* Отправка формы пациента
    async function patientupd(v) {
        v['method'] = 'patient_upd';
        await props.api(v,true);
    }

    //* Отправка формы
    async function patientprop(v) {
        for( let value in v) {
            if (value.split('-')[0] === 'date') {
                v[value] = Date.parse(v[value])/1000;
            }
        }

        v['method'] = 'patient_prop';
        await props.api(v,true);
    }

    //* Отправка формы
    async function patientcard(v) {
        for( let value in v) {
            if (value.split('-')[0] === 'date') {
                v[value] = Date.parse(v[value])/1000;
            }
        }

        v['method'] = 'patient_card';
        await props.api(v,true);
    }

    //* Отправка формы
    async function recordadd(v) {
        v['method'] = 'record_add';
        v['date'] = Date.parse(props.fulltime(v['date'],v['datet']))/1000;
        await props.api(v,true);
    }

    //* Отправка формы
    async function recorddate(v) {
        v['method'] = 'record_date';
        v['date'] = Date.parse(props.fulltime(v['date'],v['datet']))/1000;
        await props.api(v,true);
    }

    //* Отправка формы
    async function recordedit(v) {
        v['method'] = 'record_edit';
        await props.api(v,true);
    }

    async function recorddone(v) {
        v['method'] = 'record_done';
        await props.api(v,true);
    }

    //* Отправка формы
    async function rec_add_inc(v) {
        v['method'] = 'rec_add_inc';
        await form10.resetFields();
        await props.api(v,true);
    }

    //* Отправка формы
    async function cashback(v) {
        v['method'] = 'cashback_upd';
        await form12.resetFields();
        await props.api(v,true);
    }

    //* Полное время 
    function fulltime(time) {
        let date = new Date(Number(time)*1000);
        let d1 = (date.getDate()<10?'0':'') + date.getDate();
        let d2 = date.getMonth()+1;
        d2 = (d2<10?'0':'') + d2;
        let d3 = date.getFullYear();
        let d4 = (date.getHours()<10?'0':'') + date.getHours();
        let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
        return `${d3}/${d2}/${d1} ${d4}:${d5}`;
    }

    //* Вызов окна добавления
    async function patpushadd() {
        await form5.resetFields();
        await props.modal('patients_add');
        await form5.setFieldsValue({ 
            cliid:props.state.clientinfo.profile.id
        });
    }

    //* Вызов окна добавления
    async function notifpushadd() {
        await form11.resetFields();
        await props.modal('clinotif_add');
        await form11.setFieldsValue({ 
            cliid:props.state.clientinfo.profile.id
        });
    }

    //* Вызов окна обновления
    async function patpushview(id) {
        await form6.resetFields();
        await props.api({
            'method':'patient_get',
            'patid':id
        },false);
        await props.panel('patient_view');
    }

    //* Вызов окна
    async function recpushdone(id) {
        await props.modal('record_done');
    }

    //* Вызов окна обновления
    async function recordpushview(id) {
        await form9.resetFields();
        await props.api({
            'method':'record_get',
            'recordid':id,
            'cliid':props.state.clientinfo.profile.id, 
            'patid':props.state.patientinfo.profile.id
        },false);
        await props.panel('record_view');
    }

    const status = v => {
        switch(v) {
          case '0': return 'Запланирован';
          case '1': return 'Завершён';
          case '2': return 'Отменён';
          default: return 'Неизвестно';
        }
    }

    const recauthor = v => {
        switch(v) {
            case '1': return 'Автоматическая';
            case '2': return 'От врача';
            default: return 'Неизвестно';
        }
    }

    function disabledDate(current) {
        return current && current < moment().subtract(1, 'days').endOf('day');
    }

    function disabledDate2(current) {
        return current && current > moment().endOf('day');
    }

    async function proptimer() {
        props.setState({ moreload: true });
        form7.resetFields();
        await setTimeout(() => props.setState({ moreload: false }), 500);
    }

    const upload_config = {
        name: 'file',
        multiple: true,
        action: props.state.api+'upload.php',
        headers: { authorization: 'token='+props.state.da.token }
    }

    //* Загрузка примеров
    const upload_patient = {
        onChange(info) {
            var error_text = '';
            if (info.file.status === 'removed') {
                console.log('Удаление',info.file,'Новый список',info.fileList);
            }
            if (info.file.status === 'uploading') {
                console.log('Загрузка',info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} файл загружен`);
                for(let a = 0; a < info.fileList.length; a++) {
                    console.log('Загружено',info.fileList[a]['response']);
                }
                props.api({'method':'patient_docs','patid':props.state.patientinfo.profile.id},false);
            } else if (info.file.status === 'error') {
                console.log('Файл не загружен',info.file,'Новый список',info.fileList);
                if (typeof info.file.response['error'] !== "undefined" ) {
                    error_text = info.file.response.error[1];
                }
                message.error(`${info.file.name} файл не загружен. ${error_text}`);
            }
        }
    };

    //* Загрузка примеров
    const upload_record = {
        onChange(info) {
            var error_text = '';
            if (info.file.status === 'removed') {
                console.log('Удаление',info.file,'Новый список',info.fileList);
            }
            if (info.file.status === 'uploading') {
                console.log('Загрузка',info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} файл загружен`);
                for(let a = 0; a < info.fileList.length; a++) {
                    console.log('Загружено',info.fileList[a]['response']);
                }
                props.api({'method':'record_docs','recid':props.state.recordinfo.data.id},false);
            } else if (info.file.status === 'error') {
                console.log('Файл не загружен',info.file,'Новый список',info.fileList);
                if (typeof info.file.response['error'] !== "undefined" ) {
                    error_text = info.file.response.error[1];
                }
                message.error(`${info.file.name} файл не загружен. ${error_text}`);
            }
        }
    };

    //* Вызов окна добавление карты
    async function cardspushadd() {
        await form16.resetFields();
        await props.setState( { cardsitem:[] } );
        await props.modal('patient_cards_add');
        await form16.setFieldsValue( { 
            patid:props.state.patientinfo.profile.id, 
            patient_name:props.state.patientinfo.profile.name,
        } );
    }

    //* Отправка формы карты
    async function cardsadd(v) {
        v['date'] = Date.parse(v['date'])/1000;
        v['method'] = 'patient_cards_add';
        await props.api(v,false);
    }

    //* Отправка формы карты
    async function cardupd(v) {
        v['doctor_rec'] = props.state.cardsdoctors.join(',');
        v['date'] = Date.parse(v['date'])/1000;
        v['limit_date'] = Date.parse(v['limit_date'])/1000;
        v['next_date'] = Date.parse(v['next_date'])/1000;
        v['method'] = 'patient_cards_upd';
        await props.api(v,false).then(async json => {
            if (json.code === '1') {
                await cardspushview(props.state.cardsitem.card.id);
            }
        });
    }

    /**
     * It sets the form fields to the values of the card.
     * @param id - The ID of the card to be updated.
     */
    //* Вызов окна обновления
    async function cardspushview(id) {
        await form17.resetFields();
        await props.setState( { cardsdoctors: [] } );
        await props.api({ 
            'method':'patient_cards_get',
            'cardid':id
         },false).then(async json => {
            if (json.code === '1') {
                await form17.setFieldsValue( { 
                    cardid:json.info.card.id, 
                    patient_name:json.info.card.patient_name, 
                    doctor:json.info.card.doctor,
                    recommendation: json.info.card.recommendation,
                    limit_text: json.info.card.limit_text,
                    next_cost: json.info.card.next_cost
                } );

                Number(json.info.card.date) > 0 ?
                form17.setFieldsValue({ 
                    date:moment(fulltime(json.info.card.date), "YYYY-MM-DD")
                }) 
                    : 
                form17.setFieldsValue({ date:null });
                if (json.info.card.doctor_rec !== '') {
                    await props.setState( { cardsdoctors:json.info.card.doctor_rec.split(',') } );
                }

                Number(json.info.card.limit_date) > 0 ?
                form17.setFieldsValue({ 
                    limit_date:moment(fulltime(json.info.card.limit_date), "YYYY-MM-DD")
                }) 
                    : 
                form17.setFieldsValue({ limit_date:null });
                if (json.info.card.doctor_rec !== '') {
                    await props.setState( { cardsdoctors:json.info.card.doctor_rec.split(',') } );
                }

                Number(json.info.card.next_date) > 0 ?
                form17.setFieldsValue({ 
                    next_date:moment(fulltime(json.info.card.next_date), "YYYY-MM-DD")
                }) 
                    : 
                form17.setFieldsValue({ next_date:null });
                if (json.info.card.doctor_rec !== '') {
                    await props.setState( { cardsdoctors:json.info.card.doctor_rec.split(',') } );
                }
            }
        });
    }

    /**
     * It changes the list of recommended doctors.
     * @param t - The target element.
     */
    //* Изменение списка рекомендаций докторов
    async function doctors_rec(t) {
        var doctors = props.state.cardsdoctors;
        if (doctors.indexOf( t ) != -1) {
        var index = doctors.indexOf(t);
        if (index > -1) doctors.splice(index, 1);
        } else {
        await doctors.push(t);
        }
        await props.setState( { cardsdoctors:doctors } );
    }

    function splitname(name) {
        var arrayname = name.split(' ');
        var fullname = [];
        if (Object.keys(arrayname).length > 0 ) {
            fullname.push(arrayname[0]+' ');
            if (Object.keys(arrayname).length > 1 ) {
                fullname.push(arrayname[1][0]+'.');
                if (Object.keys(arrayname).length > 2 ) {
                    fullname.push(arrayname[2][0]+'.');
                }
            }
        }
        return fullname.join('');
    }

    return (
        <React.Fragment>
            <Modal className="modal-mini" width="100%" zIndex="960" key="m1" title="Просмотр приёма" mask={false} visible={props.state.panel.indexOf( 'record_view' ) != -1 } onOk={() => props.panel('record_view')} onCancel={() => props.panel('record_view')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-logo"><img alt="" src={Client_View} /></div>
                    <div className="modal-mini-header-title title-big">Просмотр приёма</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.recordinfo).length > 0 ? (
                                <div className="tworow">
                                    {'data' in props.state.recordinfo ?
                                        <div>
                                            <Divider key="d1" >Информация о приёме</Divider>
                                            <div className="print" ref={componentRef}>
                                                <Descriptions title={`Пациент`} column={1}>
                                                    <Descriptions.Item label="ФИО">{props.state.recordinfo.data.p_name}</Descriptions.Item>
                                                    <Descriptions.Item label="Тип">{props.state.recordinfo.data.p_typen}</Descriptions.Item>
                                                    <Descriptions.Item label="Примечание">{props.state.recordinfo.data.p_breed !== '' ? props.state.recordinfo.data.p_breed : 'не указано' }</Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions title={`Подробности`} column={1}>
                                                    <Descriptions.Item label="Что Вас привело?">{props.state.recordinfo.data.complaint !== '' ? props.state.recordinfo.data.complaint : 'не указано'}</Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions title={`Приём № ${props.state.recordinfo.data.id}`} column={1}>
                                                    <Descriptions.Item label="Врач">{props.state.recordinfo.data.emp1_name}</Descriptions.Item>
                                                    <Descriptions.Item label="Администратор">{props.state.recordinfo.data.emp2_name}</Descriptions.Item>
                                                    <Descriptions.Item label="Услуга">{props.state.recordinfo.data.s_name}</Descriptions.Item>
                                                    <Descriptions.Item label="Описание услуги">{props.state.recordinfo.data.s_descr}</Descriptions.Item>
                                                    <Descriptions.Item label="Дата оформления">{props.lasttime(props.state.recordinfo.data.date1,'Неизвестно когда','full')}</Descriptions.Item>
                                                    <Descriptions.Item label="Дата приёма">{props.lasttime(props.state.recordinfo.data.date2,'Неизвестно когда','full')}</Descriptions.Item>
                                                    <Descriptions.Item label="Стоимость">{props.state.recordinfo.data.price > 0 ? props.state.recordinfo.data.price+'₽' : (props.state.recordinfo.data.price < 0 ? 'Бесплатно' : 'Не указано')}</Descriptions.Item>
                                                    <Descriptions.Item label="Статус приёма">{`${status(props.state.recordinfo.data.status)}`}</Descriptions.Item>
                                                </Descriptions>
                                            </div>
                                            <div className="fcenter">
                                                <Space wrap>
                                                    <ReactToPrint
                                                        trigger={() => <Button className="des-button" type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                                        content={() => componentRef.current}
                                                    />
                                                    {props.state.recordinfo.data.status === "0" &&
                                                        <React.Fragment>
                                                            <Tooltip title={`Завершить`}>
                                                                <Button className="des-button" type="dashed" shape="round" icon={<CheckOutlined />} onClick={() => recpushdone()} >Завершить</Button>
                                                            </Tooltip>
                                                            <Button type="dashed" className="des-button" shape="round" icon={<FileSearchOutlined />} onClick={() => props.modal('record_edit')} >Изменить</Button>
                                                            <Button type="dashed" className="des-button" shape="round" icon={<CalendarOutlined />} onClick={() => props.modal('record_date')} >Перенести</Button>
                                                            <Tooltip title={`Отменить`}>
                                                                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'record_cancel', 'recordid':`${props.state.recordinfo.data.id}`, 'cliid':props.state.clientinfo.profile.id, 'patid':`${props.state.patientinfo.profile.id}` },true)} 
                                                                    title={
                                                                        <Space direction="vertical">
                                                                            <Text>Вы действительно хотите отменить данный приём?</Text>
                                                                        </Space>
                                                                    } 
                                                                    okText="Да" cancelText="Нет">
                                                                    <Button className="des-button" type="dashed" danger shape="round" icon={<CloseOutlined />} >Отменить</Button>
                                                                </Popconfirm>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    }
                                                    <Button className="des-button" type="dashed" shape="round" onClick={() => {props.modal('record_docs');setTimeout(() => setDocload(1), 1000);props.api({'method':'record_docs','recid':props.state.recordinfo.data.id},false)}} icon={<FileSearchOutlined />} >Документы</Button>
                                                </Space>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о приёме недоступна" />
                                    }
                                    {'rec' in props.state.recordinfo ?
                                        <div>
                                            <div className="print" ref={componentRef2}>
                                                <Divider key="d1" >Рекомендации</Divider>
                                                {props.state.recordinfo.rec.length > 0 ?
                                                    <Tabs type="card" className="tabsnoprint">
                                                        <TabPane tab="Все" key="0">
                                                            <Title level={5}>Основные рекомендации</Title>
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Text type="secondary">Список рекомендаций пуст</Text>
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec.filter(function(item){return(item['type'] === '2')})}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <pre>{item.descr}</pre>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                            <Title level={5}>Дополнительные рекомендации</Title>
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Text type="secondary">Список рекомендаций пуст</Text>
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec.filter(function(item){return(item['type'] === '1')})}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <pre>{item.descr}</pre>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </TabPane>
                                                        <TabPane tab="От врача" key="2">
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Empty description='Список рекомендаций пуст' />
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec.filter(function(item){return(item['type'] === '2')})}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <pre>{item.descr}</pre>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </TabPane>
                                                        <TabPane tab="Дополнительные" key="1">
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Empty description='Список рекомендаций пуст' />
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec.filter(function(item){return(item['type'] === '1')})}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <pre>{item.descr}</pre>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </TabPane>
                                                    </Tabs>
                                                :
                                                    <Empty description="Список рекомендаций пуст" />
                                                }
                                            </div>
                                            <div className="fcenter" style={{marginTop: 20}}>
                                                    <Space>
                                                        <ReactToPrint
                                                            trigger={() => <Button className="des-button" type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                                            content={() => componentRef2.current}
                                                        />
                                                        <Button className="des-button" type="dashed" shape="round" onClick={() => props.modal('rec_add_ind')} icon={<PlusOutlined />}>Добавить личную рекомендацию</Button>
                                                    </Space>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о рекомендациях недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о приёме недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini" width="100%" zIndex="950" key="m2" title="Просмотр анкеты" mask={false} visible={props.state.panel.indexOf( 'patient_view' ) != -1 } onOk={() => props.panel('patient_view')} onCancel={() => props.panel('patient_view')} footer={null}>
                <div className="modal-mini-header">
                <div className="modal-mini-header-logo"><img alt="" src={Client_View} /></div>
                    <div className="modal-mini-header-title title-big">Просмотр анкеты</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.patientinfo).length > 0 ? (
                                <div className="tworow">
                                    {'profile' in props.state.patientinfo ?
                                        <div>
                                            <Form name="form" form={form6} onFinish={patientupd} className="form" >
                                                {
                                                    form6.setFieldsValue({ 
                                                        cliid:props.state.clientinfo.profile.id,
                                                        patid:props.state.patientinfo.profile.id,
                                                        name:props.state.patientinfo.profile.name,
                                                        nametype:props.state.patientinfo.profile.nametype,
                                                        breed:props.state.patientinfo.profile.breed
                                                    })
                                                }
                                                <Divider key="d1" >Информация о пациенте</Divider>
                                                <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="name" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя пациента!' }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="nametype" label="Тип" rules={[{ required: true, message: 'Пожалуйста, укажите тип пациента!' }]}>
                                                    <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        { props.state.patientst.map( (item, index) => 
                                                            <Option disabled={Number(item.block)} key={index} value={item.id}>{item.name}</Option>
                                                        ) }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(prevValues, currentValues) => prevValues[`nametype`] !== currentValues[`nametype`]}
                                                >
                                                    {({ getFieldValue }) =>
                                                        (getFieldValue(`nametype`) !== props.state.patientinfo.profile.nametype)  ? (
                                                            <Alert className="max400" style={{marginBottom:20}} 
                                                            message={<React.Fragment><Text strong>Смена типа</Text> - обратите внимание</React.Fragment>}
                                                            description={
                                                                <Collapse ghost>
                                                                    <Panel header="Почему это важно?" key="1">
                                                                        Смена типа ведёт за собой смену рекомендаций и списка оказываемых услуг.
                                                                    </Panel>
                                                                    <Panel header="Если карточка пациента уже заполнена" key="2">
                                                                        Если ранее в карточке были указаны данные, которые характерны только для прошлого типа пациента - необходимо обнулять показания (или связаться со специалистом).
                                                                    </Panel>
                                                                </Collapse>
                                                            } type="warning" />
                                                        ) : null
                                                    }
                                                </Form.Item>
                                                <Form.Item  className="max400" name="breed" label="Примечание">
                                                    <Input />
                                                </Form.Item>
                                                <div className="fcenter">
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                        </Form.Item>
                                                        {Number(props.state.patientinfo.profile.block) === 0 ?
                                                            <Button className="des-button" type="dashed" danger shape="round" onClick={() => props.api({ 'method':'patient_block', 'cliid':props.state.clientinfo.profile.id, 'patid':`${props.state.patientinfo.profile.id}` },false)} icon={<StopOutlined />}>Заблокировать</Button>
                                                        :
                                                            <Button className="des-button" type="dashed" shape="round" onClick={() => props.api({ 'method':'patient_block', 'cliid':props.state.clientinfo.profile.id, 'patid':`${props.state.patientinfo.profile.id}` },false)} icon={<CheckOutlined />}>Разблокировать</Button>
                                                        }
                                                    </Space>
                                                </div>
                                                <div className="fcenter" style={{marginTop:20}}>
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" onClick={() => props.modal('patient_card')}>Карточка пациента</Button>
                                                        </Form.Item>
                                                        <Button className="des-button" type="dashed" shape="round" onClick={() => {props.panel('patient_docs');setTimeout(() => setDocload(1), 1000);props.api({'method':'patient_docs','patid':props.state.patientinfo.profile.id},false)}} icon={<FileSearchOutlined />} >Документы</Button>
                                                    </Space>
                                                </div>
                                                <div className="fcenter" style={{marginTop:20}}>
                                                    <Space wrap>
                                                        <Button className="des-button" type="dashed" shape="round" onClick={() => {props.panel('patient_cards');props.api({'method':'patient_cards','patid':props.state.patientinfo.profile.id},false)}} icon={<IdcardOutlined />} >Карты здоровья</Button>
                                                    </Space>
                                                </div>
                                            </Form>
                                        </div>
                                    :
                                        <Empty description="Информация о профиле недоступна" />
                                    }
                                    {'services' in props.state.patientinfo ?
                                        <div>
                                            <Divider key="d1" >Список приёмов</Divider>
                                            {props.state.patientinfo.services.length > 0 ?
                                                <Tabs type="card" className="hoverlist">
                                                    <TabPane tab="Открыт" key="1">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.patientinfo.services.filter(function(item){return(item['status'] === '0')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item 
                                                                    onClick={() => recordpushview(item.id)}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{item.name}</React.Fragment>}
                                                                        description={<React.Fragment>{props.lasttime(item.date2,'Неизвестно когда','full')}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Завершен" key="2">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.patientinfo.services.filter(function(item){return(item['status'] === '1')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item
                                                                    onClick={() => recordpushview(item.id)}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{item.name}</React.Fragment>}
                                                                        description={<React.Fragment>{props.lasttime(item.date2,'Неизвестно когда','full')}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Отменен" key="3">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.patientinfo.services.filter(function(item){return(item['status'] === '2')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item
                                                                    onClick={() => recordpushview(item.id)}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{item.name}</React.Fragment>}
                                                                        description={<React.Fragment>{props.lasttime(item.date2,'Неизвестно когда','full')}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                </Tabs>
                                            :
                                            <Empty description="Список оказанных услуг пуст" />
                                            }
                                            <div className="fcenter" style={{marginTop: 20}}>
                                                <Form.Item>
                                                    <Button className="des-button" type="dashed" shape="round" onClick={() => props.modal('record_add')} icon={<PlusOutlined />}>Новый приём</Button>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о услугах недоступна" />
                                    }
                                    {'rec' in props.state.patientinfo ?
                                        <div>
                                            <div className="print" ref={componentRef3}>
                                                <Divider key="d1" >Рекомендации</Divider>
                                                {props.state.patientinfo.rec.length > 0 ?
                                                    <List
                                                        locale={{ emptyText: 
                                                            <Empty description='Список рекомендаций пуст' />
                                                        }}
                                                        itemLayout="horizontal"
                                                        dataSource={props.state.patientinfo.rec}
                                                        renderItem={(item,index) => (
                                                            <List.Item>
                                                                <List.Item.Meta
                                                                    title={<React.Fragment>{item.name}</React.Fragment>}
                                                                    description={<React.Fragment><pre>{item.descr}</pre></React.Fragment>}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                :
                                                    <Empty description="Список рекомендаций пуст" />
                                                }
                                            </div>
                                            <div className="fcenter" style={{marginTop: 20}}>
                                                <Space>
                                                    <ReactToPrint
                                                        trigger={() => <Button className="des-button" type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                                        content={() => componentRef3.current}
                                                    />
                                                </Space>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о рекомендациях недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о пациенте недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini" width="100%" zIndex="900" key="m3" title="Просмотр анкеты" mask={false} visible={props.state.panel.indexOf( 'client_view' ) != -1 } onOk={() => props.panel('client_view')} onCancel={() => props.panel('client_view')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-logo"><img alt="" src={Client_View} /></div>
                    <div className="modal-mini-header-title title-big">Просмотр анкеты</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.clientinfo).length > 0 ? (
                                <div className="tworow">
                                    {'profile' in props.state.clientinfo ?
                                        <div>
                                            <Form name="form" form={form1} onFinish={clientupd} className="form">
                                                { form1.setFieldsValue({ 
                                                    cliid:props.state.clientinfo.profile.id,
                                                    lastname:props.state.clientinfo.profile.lastname,
                                                    firstname:props.state.clientinfo.profile.firstname,
                                                    patronymic:props.state.clientinfo.profile.patronymic,
                                                    phone:props.state.clientinfo.profile.phone.slice(1),
                                                    email:props.state.clientinfo.profile.email,
                                                    sex:props.state.clientinfo.profile.sex,
                                                    address:props.state.clientinfo.profile.address,
                                                    cashback:props.state.clientinfo.profile.cashback
                                                }) }
                                                {
                                                    Number(props.state.clientinfo.profile.birthday) > 0 ?
                                                    form1.setFieldsValue({ 
                                                        birthday:moment(fulltime(props.state.clientinfo.profile.birthday), "YYYY-MM-DD")
                                                    }) : 
                                                    form1.setFieldsValue({ birthday:null })
                                                }
                                                <Divider key="d1" >Контактные данные</Divider>
                                                <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="lastname" label="Фамилия" rules={[{ required: true, message: 'Пожалуйста, укажите фамилию клиента!' }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="firstname" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя клиента!' }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="patronymic" label="Отчество" >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="birthday" label="Дата рождения" >
                                                    <DatePicker 
                                                        format="DD.MM.YYYY"
                                                        className="datepickerfull"
                                                    />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="address" label="Адрес" >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="phone" label="Номер" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона клиента!' }]}>
                                                    <MaskedInput
                                                        prefix="+7"
                                                        mask="111-111-11-11"
                                                        placeholder="999-999-99-99"
                                                        size="9"
                                                        formatCharacters={{
                                                        'W': {
                                                            validate(char) { return /\w/.test(char ) },
                                                            transform(char) { return char.toUpperCase() }
                                                        }
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="email" label="E-mail" >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="sex" label="Пол" rules={[{ required: true, message: 'Пожалуйста, укажите пол клиента!' }]}>
                                                    <Select>
                                                        <Option value="0">Неизвестно</Option>
                                                        <Option value="1">Мужской</Option>
                                                        <Option value="2">Женский</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item  className="max400" name="cashback" label="Бонусы" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <div className="fcenter">
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                        </Form.Item>
                                                        <Button className="des-button" type="dashed" shape="round" onClick={() => props.api({ 'method':'client_pas', 'cliid':props.state.clientinfo.profile.id },false)} >Выслать пароль</Button>
                                                        {Number(props.state.clientinfo.profile.block) === 0 ?
                                                            <Button className="des-button" type="dashed" danger shape="round" onClick={() => props.api({ 'method':'client_block', 'cliid':props.state.clientinfo.profile.id },false)} icon={<StopOutlined />}>Заблокировать</Button>
                                                        :
                                                            <Button className="des-button des-green" type="dashed" shape="round" onClick={() => props.api({ 'method':'client_block', 'cliid':props.state.clientinfo.profile.id },false)} icon={<CheckOutlined />}>Разблокировать</Button>
                                                        }
                                                    </Space>
                                                </div>
                                                <div className="fcenter" style={{marginTop:20}}>
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" onClick={() => props.modal('cashback')}>Бонусы</Button>
                                                        </Form.Item>
                                                    </Space>
                                                </div>
                                            </Form>
                                        </div>
                                    :
                                        <Empty description="Информация о профиле недоступна" />
                                    }
                                    {'patients' in props.state.clientinfo ?
                                        <div>
                                            <Divider key="d1" >Пациенты</Divider>
                                            { props.state.clientinfo.patients.length > 0 ?
                                                <Space wrap className="cardlist c_patients">
                                                    {props.state.clientinfo.patients.map( list => 
                                                        <Card 
                                                            bordered={true} 
                                                            style={{width:'100%'}}
                                                            key={list.id}
                                                            onClick={() => patpushview(list.id)}
                                                            hoverable
                                                        >
                                                            <Meta title={list.name} description={list.nametype} />
                                                        </Card>
                                                    )
                                                    }
                                                </Space>
                                            :
                                                <div className="fcenter"><Empty description="Список пуст" /></div>
                                            }
                                            <div className="fcenter" style={{marginTop:20}}>
                                                <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => patpushadd()} />
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о пациентах недоступна" />
                                    }
                                    {'notif' in props.state.clientinfo ?
                                        <div>
                                            <Divider key="d1" >Напоминания</Divider>
                                            { props.state.clientinfo.notif.length > 0 ?
                                                <Tabs type="card">
                                                    <TabPane tab="Все" key="0">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список напоминаний пуст' />
                                                            }}
                                                            pagination={{ pageSize: 10 }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.clientinfo.notif}
                                                            renderItem={(item,index) => (
                                                                <List.Item actions={[
                                                                    <Tooltip title={`Удалить`}>
                                                                        <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'client_notif_del', 'clinotid':`${item.id}`, 'cliid':props.state.clientinfo.profile.id },true)} 
                                                                            title={
                                                                                <Space direction="vertical">
                                                                                    <Text>Вы действительно хотите удалить данное напоминание?</Text>
                                                                                </Space>
                                                                            } 
                                                                        okText="Да" cancelText="Нет">
                                                                            <Link type="danger" key="a2"><CloseOutlined /></Link>
                                                                        </Popconfirm>
                                                                    </Tooltip>
                                                                ]}>
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{props.lasttime(item.date,'Неизвестно когда','full')}</React.Fragment>}
                                                                        description={<React.Fragment>{item.text}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Активные" key="1">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список напоминаний пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.clientinfo.notif.filter(function(item){return(item['active'] === '1')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item actions={[
                                                                    <Tooltip title={`Удалить`}>
                                                                        <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'client_notif_del', 'clinotid':`${item.id}`, 'cliid':props.state.clientinfo.profile.id },true)} 
                                                                            title={
                                                                                <Space direction="vertical">
                                                                                    <Text>Вы действительно хотите удалить данное напоминание?</Text>
                                                                                </Space>
                                                                            } 
                                                                        okText="Да" cancelText="Нет">
                                                                            <Link type="danger" key="a2"><CloseOutlined /></Link>
                                                                        </Popconfirm>
                                                                    </Tooltip>
                                                                ]}>
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{props.lasttime(item.date,'Неизвестно когда','full')}</React.Fragment>}
                                                                        description={<React.Fragment>{item.text}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Прошлые" key="2">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список напоминаний пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.clientinfo.notif.filter(function(item){return(item['active'] === '0')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item actions={[
                                                                    <Tooltip title={`Удалить`}>
                                                                        <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'client_notif_del', 'clinotid':`${item.id}`, 'cliid':props.state.clientinfo.profile.id },true)} 
                                                                            title={
                                                                                <Space direction="vertical">
                                                                                    <Text>Вы действительно хотите удалить данное напоминание?</Text>
                                                                                </Space>
                                                                            } 
                                                                        okText="Да" cancelText="Нет">
                                                                            <Link type="danger" key="a2"><CloseOutlined /></Link>
                                                                        </Popconfirm>
                                                                    </Tooltip>
                                                                ]}>
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{props.lasttime(item.date,'Неизвестно когда','full')}</React.Fragment>}
                                                                        description={<React.Fragment>{item.text}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                </Tabs>
                                            :
                                                <div className="fcenter"><Empty description="Список пуст" /></div>
                                            }
                                            <div className="fcenter" style={{marginTop:20}}>
                                                <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => notifpushadd()} />
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о напоминаниях недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о клиенте недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m4" width={500} title="Управление бонусами" mask={false} visible={props.state.modal === 'cashback'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Управление бонусами</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'profile' in props.state.clientinfo ?
                                <Form name="form" form={form12} onFinish={cashback} className="form" layout="vertical">
                                    {
                                            form12.setFieldsValue({ 
                                            cliid:props.state.clientinfo.profile.id
                                        })
                                    }
                                    <Form.Item className="max400" name='type' label='Действие' rules={[{ required: true, message: 'Пожалуйста, выберите действие!' }]} >
                                        <Select>
                                            <Option value="1">Начислить</Option>
                                            <Option value="2">Списать</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className="max400" name="count" label="Количество" rules={[{ required: true, message: 'Пожалуйста, укажите количество!' }]} >
                                        <InputNumber style={{width:'100%'}} />
                                    </Form.Item>
                                    <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                        <Input />
                                    </Form.Item>
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            :
                                <Empty description="Информация о рекомендации недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m5" width={500} title="Личная рекомендация" mask={false} visible={props.state.modal === 'rec_add_ind'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Личная рекомендация</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'data' in props.state.recordinfo ?
                                <Form name="form" form={form10} onFinish={rec_add_inc} className="form" layout="vertical">
                                    {
                                            form10.setFieldsValue({ 
                                            cliid:props.state.clientinfo.profile.id,
                                            patid:props.state.patientinfo.profile.id,
                                            recordid:props.state.recordinfo.data.id,
                                        })
                                    }
                                    <Form.Item  className="max400" name="descr" label="" rules={[{ required: true, message: 'Пожалуйста, напишите рекомендацию!' }]} >
                                        <TextArea showCount maxLength={2000} rows={4} />
                                    </Form.Item>
                                    <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden name={`recordid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                        <Input />
                                    </Form.Item>
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            :
                                <Empty description="Информация о рекомендации недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m7" mask={false} width={500} title="Карточка пациента" mask={false} visible={props.state.modal === 'patient_card'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Карточка пациента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload ?
                        <React.Fragment>
                            {'card' in props.state.patientinfo ?
                                <React.Fragment>
                                    {Object.keys(props.state.patientinfo['card']).length > 0 ? (
                                        <Form name="form15" form={form15} onFinish={patientcard} className="form" layout="vertical">
                                            {
                                                props.state.patientinfo.card.map(prop => {
                                                    return form15.setFieldsValue({ [`prop-${prop.id}`]:prop.status });
                                                })
                                            }
                                            {
                                                form15.setFieldsValue({ 
                                                    cliid:props.state.clientinfo.profile.id,
                                                    patid:props.state.patientinfo.profile.id
                                                })
                                            }
                                            { props.state.patientinfo.card.map( (prop, index) => 
                                                <React.Fragment>
                                                    <div>
                                                        <Form.Item key={prop.id} name={`prop-${prop.id}`} label={`${prop.review}`} >
                                                            <Select>
                                                                <Option value="0">Неизвестно</Option>
                                                                <Option value="1">Да</Option>
                                                                <Option value="2">Нет</Option>
                                                                <Option value="3">Процедура уже оказывается</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <Divider />
                                                </React.Fragment>
                                            ) }
                                            <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                <Input />
                                            </Form.Item>
                                            <div className="fcenter">
                                                <Form.Item>
                                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    ) : (
                                        <Empty description="Информация о карточке недоступна" />
                                    )}
                                </React.Fragment>
                            :
                                <Empty description="Информация о профиле недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" zIndex="951" key="m6" width={1200} title="Документы пациента" mask={false} visible={props.state.panel.indexOf( 'patient_docs' ) != -1 } onOk={() => props.panel('patient_docs')} onCancel={() => props.panel('patient_docs')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Документы пациента</div>
                </div>
                <div className="modal-mini-body">
                    {'profile' in props.state.patientinfo ? (
                        <React.Fragment>
                            <Space wrap style={{width:'100%',justifyContent:'left'}}>
                                <Form style={{maxWidth:'210px'}} name="form" form={formsearch} >
                                    <Form.Item key="search" name="search" style={{marginBottom:0}}>
                                        <Input placeholder="Введите название" prefix={<SearchOutlined />} onChange={ onSearch } className="button_radius" />
                                    </Form.Item>
                                </Form>
                            </Space>
                            {docload ?
                                <Dragger 
                                    {...upload_config}
                                    {...upload_patient} 
                                    data={{'type':'doc','client':props.state.clientinfo.profile.id,'patient':props.state.patientinfo.profile.id}}
                                    className="upload_custom" 
                                    style={{borderRadius:'15px',margin:'10px 0'}}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Нажмите или перенесите файлы для загрузки</p>
                                </Dragger>
                            :
                                <React.Fragment>
                                    <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                                </React.Fragment>
                            }
                            {!props.state.moreload ?
                                <React.Fragment>
                                    <Table 
                                        className="table-padding table-white"
                                        locale={{ emptyText: <Empty description="Список пуст" />}} 
                                        dataSource={obj.doclist} 
                                        scroll={{ x: 400 }} 
                                        pagination={{ position: ['none', 'bottomLeft'] }}
                                        rowKey="table1" 
                                        onChange={props.htc}
                                        columns={columns2}
                                    >
                                    </Table>
                                </React.Fragment>
                            :
                                <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                            }
                        </React.Fragment>
                    ) : (
                        <Empty description="Информация о пациенте недоступна" />
                    ) }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" zIndex="951" key="m6_1" width={1200} title="Карты здоровья пациента" mask={false} visible={props.state.panel.indexOf( 'patient_cards' ) != -1 } onOk={() => props.panel('patient_cards')} onCancel={() => props.panel('patient_cards')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Карты здоровья пациента</div>
                </div>
                <div className="modal-mini-body">
                    {'profile' in props.state.patientinfo ? (
                        <React.Fragment>
                            <Space wrap style={{width:'100%',justifyContent:'left',marginBottom:'10px'}}>
                                <Form style={{maxWidth:'210px'}} name="form" form={formsearchcard} >
                                    <Form.Item key="search" name="search" style={{marginBottom:0}}>
                                        <Input placeholder="Введите ФИО врача" prefix={<SearchOutlined />} onChange={ onCards } className="button_radius" />
                                    </Form.Item>
                                </Form>
                            </Space>
                            {!props.state.moreload ?
                                <React.Fragment>
                                    <div className="fcenter">
                                        <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => cardspushadd()} />
                                    </div>
                                    <Table 
                                        style={{margin:'10px 0'}}
                                        className="table-padding table-white"
                                        locale={{ emptyText: <Empty description="Список пуст" />}} 
                                        dataSource={obj.cardlist} 
                                        scroll={{ x: 400 }} 
                                        pagination={{ position: ['none', 'bottomLeft'] }}
                                        rowKey="table1" 
                                        onChange={props.htc}
                                        columns={columns4}
                                    >
                                    </Table>
                                    <div className="fcenter">
                                        <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => cardspushadd()} />
                                    </div>
                                </React.Fragment>
                            :
                                <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                            }
                        </React.Fragment>
                    ) : (
                        <Empty description="Информация о пациенте недоступна" />
                    ) }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" zIndex="951" key="m6_2" width={1200} title="Просмотр карты здоровья" mask={false} visible={props.state.panel.indexOf( 'patient_cards_view' ) != -1 } onOk={() => props.panel('patient_cards_view')} onCancel={() => props.panel('patient_cards_view')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Просмотр карты здоровья</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload ? (
                        <React.Fragment>
                            {'card' in props.state.cardsitem ?
                                <React.Fragment>
                                    <Space direction="vertical" style={{width:'100%'}}>
                                        <div className="fcenter">
                                            <Avatar size={100} src={props.state.cardsitem.card.doctor_ava !== '' ? `https://clinic.rus2.ru/km/41//uploads/${props.state.cardsitem.card.doctor_ava}` : ''} />
                                        </div>
                                        <div className="fcenter">
                                            <Title level={4}>{props.state.cardsitem.card.doctor_name}</Title>
                                        </div>
                                        {
                                            props.state.cardsitem.card.doctor_specialization !== '' &&
                                            <div className="fcenter">
                                                <Title style={{maxWidth:'300px'}} level={5}>{props.state.cardsitem.card.doctor_specialization}</Title>
                                            </div>
                                        }
                                        <div className="fcenter">
                                            <Button className="des-button" type="dashed" shape="round" icon={<PrinterOutlined />} onClick={() => props.panel('patient_cards_print')} >Открыть информацию для печати</Button>
                                        </div>
                                        <Form name="form" form={form17} onFinish={cardupd} className="form" layout="vertical">
                                            <Tabs type="card" className="wowload custom_tabs">
                                                <TabPane tab="Основные данные" key="1" >
                                                    <Divider />
                                                    <Form.Item hidden name={`cardid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID карточки!` }]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item name={`patient_name`} label='ФИО пациента' rules={[{ required: true, message: `Пожалуйста, введите ФИО пациента!` }]}>
                                                        <Input disabled suffix={<Tooltip title={`ФИО пациента`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                                                    </Form.Item>
                                                    <Form.Item name="date" label="Дата визита" rules={[{ required: true, message: 'Пожалуйста, укажите дату визита!' }]}>
                                                        <DatePicker
                                                            format="DD.MM.YYYY"
                                                            className="datepickerfull"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="doctor" label="Врач" rules={[{ required: true, message: `Пожалуйста, выберите врача!` }]}>
                                                        <Select showSearch optionFilterProp="children">
                                                            { props.state.pers.map( (item, index) => 
                                                                <Option key={index} value={item.id}>
                                                                    <Space><Avatar className="ava_padding" src={item.ava !== '' ? `https://clinic.rus2.ru/km/41//uploads/${item.ava}` : ''} /><span>{item.name}</span></Space>
                                                                </Option>
                                                            ) }
                                                        </Select>
                                                    </Form.Item>
                                                </TabPane>
                                                <TabPane tab="Рекомендации" key="2" >
                                                    <Form.Item name="recommendation" label="Рекомендации">
                                                        <TextArea showCount maxLength={4000} rows={9} />
                                                    </Form.Item>
                                                    <div className="pers_list">
                                                        { obj.pers.map( (item, index) => 
                                                            <div key={index}>
                                                                <Avatar size={100} style={{marginBottom:'10px'}} className="ava_padding" src={item.ava !== '' ? `https://clinic.rus2.ru/km/41//uploads/${item.ava}` : ''} />
                                                                <Title style={{textAlign:'center'}} level={4}>{item.name}</Title>
                                                                {
                                                                    item.specialization !== '' &&
                                                                    <div className="fcenter">
                                                                        <Title style={{maxWidth:'300px'}} level={5}>{item.specialization}</Title>
                                                                    </div>
                                                                }
                                                                <Button onClick={() => doctors_rec(item.id)} danger={props.state.cardsdoctors.indexOf( item.id ) != -1 ? false : true} style={{marginTop:'auto'}} type="primary" shape="round" icon={<CheckOutlined />} >Рекомендовать</Button>
                                                            </div>
                                                        ) }
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Прочее" key="3" >
                                                    <Divider orientation="left">Ограничения</Divider>
                                                    <Form.Item name={`limit_text`} label='Ограничения'>
                                                        <Input showCount maxLength={100} />
                                                    </Form.Item>
                                                    <Form.Item name="limit_date" label="Дата">
                                                        <DatePicker
                                                            format="DD.MM.YYYY"
                                                            className="datepickerfull"
                                                        />
                                                    </Form.Item>
                                                    <Divider orientation="left">Следующий осмотр</Divider>
                                                    <Form.Item name="next_date" label="Дата">
                                                        <DatePicker
                                                            format="DD.MM.YYYY"
                                                            className="datepickerfull"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name={`next_cost`} label='Ориентировочная стоимость следующего визита'>
                                                        <InputNumber style={{width:'100%'}} min={0} />
                                                    </Form.Item>
                                                </TabPane>
                                            </Tabs>
                                            <Divider />
                                            <div className="fcenter">
                                                <Form.Item>
                                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    </Space>
                                </React.Fragment>
                            :
                                <Empty description="Данные карточки недоступны" />
                            }
                        </React.Fragment>
                    ) : (
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    ) }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" zIndex="951" key="m6_3" width={1200} title="Печать карты здоровья пациента" mask={false} visible={props.state.panel.indexOf( 'patient_cards_print' ) != -1 } onOk={() => props.panel('patient_cards_print')} onCancel={() => props.panel('patient_cards_print')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Печать карты здоровья пациента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload ? (
                        <React.Fragment>
                            {'card' in props.state.cardsitem ?
                                <React.Fragment>
                                    <div className="fcenter" style={{marginBottom:'20px'}}>
                                        <ReactToPrint
                                            trigger={() => <Button className="des-button" type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                            content={() => componentRef4.current}
                                        />
                                    </div>
                                    <div className="print cards_print" ref={componentRef4}>
                                        <div className="cards_header">
                                            <div className="cards_logo">

                                            </div>
                                            <div className="cards_visit">
                                                <div className="cards_visit_text cards_mm">
                                                    Дата визита
                                                </div>
                                                <div className="cards_visit_center cards_mm">
                                                    {props.lasttime(props.state.cardsitem.card.date,'','micro')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cards_body">
                                            <p className="cards_mm">
                                                Все диагнозы подтверждаются 2D или 3D снимком в соответствии с мировыми стандартами качества
                                            </p>
                                            <p className="cards_title">
                                                Индивидуальная карта здоровья пациента  
                                            </p>
                                            <div className="cards_text">
                                                <div className="cards_visit_text cards_mm">
                                                    ФИО пациента
                                                </div>
                                                <div className="cards_visit_left cards_mm">
                                                    {props.state.cardsitem.card.patient_name}
                                                </div>
                                            </div>
                                            <p className="cards_title_spec">Рекомендована консультация специалиста:</p>
                                            <div className="cards_spec_list">
                                                { obj.pers.map( (item, index) => 
                                                    <div className="cards_spec_item" key={index}>
                                                        <div className="cards_spec_avarar">
                                                            <img src={item.ava !== '' ? `https://clinic.rus2.ru/km/41//uploads/${item.ava}` : ''} />
                                                            <div className={`cards_spec_rec ${props.state.cardsdoctors.indexOf( item.id ) != -1 ? 'rec_true' : ''}`}>

                                                            </div>
                                                        </div>
                                                        <div className="cards_spec_name">
                                                            {item.name}
                                                        </div>
                                                        {
                                                            item.specialization !== '' &&
                                                            <div className="cards_spec_spec">
                                                                {item.specialization}
                                                            </div>
                                                        }
                                                    </div>
                                                ) }
                                            </div>
                                            <div style={{pageBreakAfter: 'always'}}>

                                            </div>
                                            <p className="cards_title_rec cards_mm">
                                                Рекомендации по итогам приема:
                                            </p>
                                            <div className="cards_rec_text">
                                                <pre className="cards_mm">{props.state.cardsitem.card.recommendation}</pre>
                                            </div>
                                            <div className="cards_text cards_margin_bottom_20">
                                                <div className="cards_visit_text cards_mm">
                                                    Ограничения
                                                </div>
                                                <div className="cards_mm">
                                                    {props.state.cardsitem.card.limit_text}
                                                </div>
                                                <div className="cards_visit_1 cards_mm">
                                                    /
                                                </div>
                                                <div className="cards_visit_right_date cards_mm">
                                                    {props.lasttime(props.state.cardsitem.card.limit_date,'','micro')}
                                                </div>
                                            </div>
                                            <div className="cards_text cards_visit_date">
                                                <div className="cards_visit_text cards_mm">
                                                    Следующий осмотр:
                                                </div>
                                                <div className="cards_visit_date_text cards_mm">
                                                    {props.lasttime(props.state.cardsitem.card.next_date,'','micro')}
                                                </div>
                                            </div>
                                            <div className="cards_text cards_visit_cost">
                                                <div className="cards_visit_text cards_mm">
                                                    Ориентировочная стоимость следующего визита:
                                                </div>
                                                <div className="cards_visit_date_text cards_mm">
                                                    {props.state.cardsitem.card.next_cost}
                                                </div>
                                            </div>
                                            <div className="cards_text cards_margin_bottom_20">
                                                <div className="cards_visit_text cards_mm">
                                                    Подпись врача:
                                                </div>
                                                <div className="cards_visit_1 cards_mm">
                                                    (
                                                </div>
                                                <div className="cards_visit_name cards_mm">
                                                    {splitname(props.state.cardsitem.card.doctor_name)}
                                                </div>
                                                <div className="cards_visit_2 cards_mm">
                                                    )
                                                </div>
                                            </div>
                                            <div className="cards_text cards_margin_bottom_20">
                                                <div className="cards_visit_text cards_mm">
                                                    Подпись пациента:
                                                </div>
                                                <div className="cards_visit_1 cards_mm">
                                                    (
                                                </div>
                                                <div className="cards_visit_name cards_mm">
                                                    {splitname(props.state.cardsitem.card.patient_name)}
                                                </div>
                                                <div className="cards_visit_2 cards_mm">
                                                    )
                                                </div>
                                            </div>
                                            <p>
                                                -
                                            </p>
                                        </div>
                                        <div className="cards_footer">
                                            <p className="cards_title">
                                                Наши преимущества  
                                            </p>
                                        </div>
                                    </div>
                                    <div className="fcenter" style={{marginTop:'20px'}}>
                                        <ReactToPrint
                                            trigger={() => <Button className="des-button" type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                            content={() => componentRef4.current}
                                        />
                                    </div>
                                </React.Fragment>
                            :
                                <Empty description="Данные карточки недоступны" />
                            }
                        </React.Fragment>
                    ) : (
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    ) }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m8" title="Добавление индивидуальной карты здоровья" mask={false} visible={props.state.modal === 'patient_cards_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление индивидуальной карты здоровья</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form16} onFinish={cardsadd} className="form" layout="vertical">
                            <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID пациента!` }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={`patient_name`} label='ФИО пациента' rules={[{ required: true, message: `Пожалуйста, введите ФИО пациента!` }]}>
                                <Input disabled suffix={<Tooltip title={`ФИО пациента`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name="date" label="Дата визита" rules={[{ required: true, message: 'Пожалуйста, укажите дату визита!' }]}>
                                <DatePicker
                                    format="DD.MM.YYYY"
                                    className="datepickerfull"
                                />
                            </Form.Item>
                            <Form.Item name="doctor" label="Врач" rules={[{ required: true, message: `Пожалуйста, выберите врача!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    { props.state.pers.map( (item, index) => 
                                        <Option key={index} value={item.id}>
                                            <Space><Avatar className="ava_padding" src={item.ava !== '' ? `https://clinic.rus2.ru/km/41//uploads/${item.ava}` : ''} /><span>{item.name}</span></Space>
                                        </Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m9" width={500} title="Завершение приёма" mask={false} visible={props.state.modal === 'record_done'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Завершение приёма</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'data' in props.state.recordinfo ?
                                <React.Fragment>
                                    <Form name="form" form={form13} onFinish={recorddone} className="form" layout="vertical">
                                        {
                                            form13.setFieldsValue({ 
                                                recordid:props.state.recordinfo.data.id,
                                                cliid:props.state.clientinfo.profile.id,
                                                patid:props.state.patientinfo.profile.id,
                                                cashback:props.state.clientinfo.profile.cashback
                                            })
                                        }
                                        <Form.Item hidden name={`recordid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item className="max400" name="cashback" label="Списать бонусы" >
                                            <InputNumber style={{width:'100%'}} min={0} max={props.state.clientinfo.profile.cashback} />
                                        </Form.Item>
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button className="des-button" type="dashed" shape="round" htmlType="submit">Завершить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </React.Fragment>
                            :
                                <Empty description="Информация о приёме недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m10" width={500} title="Новый приём" mask={false} visible={props.state.modal === 'record_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Новый приём</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'servicelist' in props.state.patientinfo ?
                                <React.Fragment>
                                    <Form name="form" form={form8} onFinish={recordadd} className="form" layout="vertical">
                                        {
                                            form8.setFieldsValue({ 
                                                cliid:props.state.clientinfo.profile.id,
                                                patid:props.state.patientinfo.profile.id,
                                                emp1:"0",
                                                emp2:"0",
                                                cashback:props.state.clientinfo.profile.cashback
                                            })
                                        }
                                        <Form.Item className="max400" name="service" label="Услуга" rules={[{ required: true, message: 'Пожалуйста, укажите услугу' }]}>
                                            <Select showSearch optionFilterProp="children" >
                                                { props.state.patientinfo.servicelist.map( (list, index) => 
                                                    <OptGroup key={index} label={list.name}>
                                                        { list['list'].map( item => 
                                                            <Option disabled={Number(item.block)} key={`option-${item.id}`} value={item.id}>
                                                                <div className="serv_col">
                                                                    <div>{`${item.descr.length > 0 ? `${item.descr}` : `${item.name}`}`}</div>
                                                                    <div>Стоимость: {item.price > 0 ? item.price : 'бесплатно'}</div>
                                                                </div>
                                                            </Option>
                                                        ) }
                                                    </OptGroup>
                                                ) }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="max400" name="comment" label="Дополнительная информация" >
                                            <TextArea showCount maxLength={1000} rows={2} />
                                        </Form.Item>
                                        <Form.Item className="max400" name="date" label="Дата" rules={[{ required: true, message: 'Пожалуйста, укажите дату приёма!' }]}>
                                            <DatePicker
                                                format="DD.MM.YYYY"
                                                className="datepickerfull"
                                            />
                                        </Form.Item>
                                        <Form.Item name="datet" className="max400" label="Время">
                                            <TimeField
                                                colon=":"
                                                input={<Input />}
                                            />
                                        </Form.Item>
                                        <Form.Item className="max400" name="emp1" label="Врач" >
                                            <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                <Option value="0">Неизвестно</Option>
                                                { props.state.emp[1].map( (item, index) => 
                                                    <Option key={index} value={item.id}>{item.name}</Option>
                                                ) }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="max400" name="emp2" label="Администратор" >
                                            <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                <Option value="0">Неизвестно</Option>
                                                { props.state.emp[0].map( (item, index) => 
                                                    <Option key={index} value={item.id}>{item.name}</Option>
                                                ) }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="max400" name="complaint" label="Что Вас привело?" >
                                            <TextArea showCount maxLength={1000} rows={4} />
                                        </Form.Item>
                                        <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </React.Fragment>
                            :
                                <Empty description="Информация о профиле недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m11" width={600} title="Добавление клиента" mask={false} visible={props.state.modal === 'cli_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление клиента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form3} onFinish={cliadd} className="form" layout="vertical">
                            <Form.Item  className="max400" name="lastname" label="Фамилия" rules={[{ required: true, message: 'Пожалуйста, укажите фамилию клиента!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="firstname" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя клиента!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="patronymic" label="Отчество" >
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="birthday" label="Дата рождения" >
                                <DatePicker 
                                    format="DD.MM.YYYY"
                                    className="datepickerfull"
                                />
                            </Form.Item>
                            <Form.Item  className="max400" name="address" label="Адрес" >
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="phone" label="Номер" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона клиента!' }]}>
                                <MaskedInput
                                    prefix="+7"
                                    mask="111-111-11-11"
                                    placeholder="999-999-99-99"
                                    size="9"
                                    formatCharacters={{
                                    'W': {
                                        validate(char) { return /\w/.test(char ) },
                                        transform(char) { return char.toUpperCase() }
                                    }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item  className="max400" name="email" label="E-mail" >
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="sex" label="Пол" rules={[{ required: true, message: 'Пожалуйста, укажите пол клиента!' }]}>
                                <Select>
                                    <Option value="0">Неизвестно</Option>
                                    <Option value="1">Мужской</Option>
                                    <Option value="2">Женский</Option>
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить анкету</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m12" width={500} title="Добавление пациента" mask={false} visible={props.state.modal === 'patients_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление пациента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form5} onFinish={patadd} className="form" layout="vertical">
                            <Form.Item hidden  className="max400" name="cliid" label="ID" rules={[{ required: true, message: 'Пожалуйста, укажите ID!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="name" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя пациента!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="nametype" label="Тип" rules={[{ required: true, message: 'Пожалуйста, укажите тип пациента!' }]}>
                                <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    { props.state.patientst.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>{item.name}</Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item  className="max400" name="breed" label="Примечание">
                                <Input />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m13" width={500} title="Добавление напоминания" mask={false} visible={props.state.modal === 'clinotif_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление напоминания</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form11} onFinish={notifadd} className="form" layout="vertical">
                            <Form.Item hidden  className="max400" name="cliid" label="ID" rules={[{ required: true, message: 'Пожалуйста, укажите ID!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="date" label="Дата отправки" rules={[{ required: true, message: 'Пожалуйста, укажите дату!' }]}>
                                <DatePicker
                                    format="DD.MM.YYYY"
                                />
                            </Form.Item>
                            <Form.Item name="datet" className="max400" label="Время">
                                <TimeField
                                    colon=":"
                                    input={<Input />}
                                />
                            </Form.Item>
                            <Form.Item  className="max400" name="text" label="" rules={[{ required: true, message: 'Пожалуйста, напишите напоминание!' }]} >
                                <TextArea showCount maxLength={100} rows={4} />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m14" width={500} mask={false} visible={props.state.modal === 'record_date'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'data' in props.state.recordinfo ?
                                <React.Fragment>
                                    <Form name="form" form={form9} onFinish={recorddate} className="form" layout="vertical">
                                        {
                                            form9.setFieldsValue({ 
                                                date:moment(fulltime(props.state.recordinfo.data.date2), "YYYY-MM-DD"),
                                                datet:moment(fulltime(props.state.recordinfo.data.date2)).format('HH:mm'),
                                                cliid:props.state.clientinfo.profile.id,
                                                patid:props.state.patientinfo.profile.id,
                                                recordid:props.state.recordinfo.data.id,
                                            })
                                        }
                                        <Form.Item  className="max400" name="date" label="Дата" rules={[{ required: true, message: 'Пожалуйста, укажите дату!' }]}>
                                            <DatePicker
                                                format="DD.MM.YYYY"
                                            />
                                        </Form.Item>
                                        <Form.Item name="datet" className="max400" label="Время">
                                            <TimeField
                                                colon=":"
                                                input={<Input />}
                                            />
                                        </Form.Item>
                                        <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`recordid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </React.Fragment>
                            :
                                <Empty description="Информация о дате недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m15" width={600} title="Информация о приёме" mask={false} visible={props.state.modal === 'record_edit'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Информация о приёме</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'data' in props.state.recordinfo ?
                                <React.Fragment>
                                    <Form name="form" form={form14} onFinish={recordedit} className="form" layout="vertical">
                                        {
                                            form14.setFieldsValue({ 
                                                cliid:props.state.clientinfo.profile.id,
                                                patid:props.state.patientinfo.profile.id,
                                                recordid:props.state.recordinfo.data.id,
                                                complaint:props.state.recordinfo.data.complaint,
                                                comment:props.state.recordinfo.data.comment
                                            })
                                        }
                                        <Form.Item name="comment" label="Дополнительная информация к приёму" >
                                            <TextArea showCount maxLength={1000} rows={2} />
                                        </Form.Item>
                                        <Form.Item name="complaint" label="Что Вас привело?" >
                                            <TextArea showCount maxLength={1000} rows={4} />
                                        </Form.Item>
                                        <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`recordid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </React.Fragment>
                            :
                                <Empty description="Информация о дате недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m16" width={1200} title="Документы приема" mask={false} visible={props.state.modal === 'record_docs'} onOk={() => {props.setState( { modal:'',docs:[] } );setDocload(0)}} onCancel={() => {props.setState( { modal:'',docs:[] } );setDocload(0)}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Документы приема</div>
                </div>
                <div className="modal-mini-body">
                    {'data' in props.state.recordinfo ? (
                        <React.Fragment>
                            <Space wrap style={{width:'100%',justifyContent:'left'}}>
                                <Form style={{maxWidth:'210px'}} name="form" form={formsearch} >
                                    <Form.Item key="search" name="search" style={{marginBottom:0}}>
                                        <Input placeholder="Введите название" prefix={<SearchOutlined />} onChange={ onSearch } className="button_radius" />
                                    </Form.Item>
                                </Form>
                            </Space>
                            {docload ?
                                <Dragger 
                                    {...upload_config}
                                    {...upload_record}
                                    data={{'type':'record','client':props.state.clientinfo.profile.id,'patient':props.state.patientinfo.profile.id,'record':props.state.recordinfo.data.id}}
                                    className="upload_custom" 
                                    style={{borderRadius:'15px',margin:'10px 0'}}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Нажмите или перенесите файлы для загрузки</p>
                                </Dragger>
                            :
                                <React.Fragment>
                                    <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                                </React.Fragment>
                            }
                            {!props.state.moreload ?
                                <React.Fragment>
                                    <Table 
                                        className="table-padding table-white"
                                        locale={{ emptyText: <Empty description="Список пуст" />}} 
                                        dataSource={obj.doclist} 
                                        scroll={{ x: 400 }} 
                                        pagination={{ position: ['none', 'bottomLeft'] }}
                                        rowKey="table1" 
                                        onChange={props.htc}
                                        columns={columns3}
                                    >
                                    </Table>
                                </React.Fragment>
                            :
                                <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                            }
                        </React.Fragment>
                    ) : (
                        <Empty description="Информация о пациенте недоступна" />
                    ) }
                </div>
            </Modal>
            <Row className="wowload">
                <Col key="k1_clients" span={24} style={{marginBottom:10}} >
                    <div className="floattask"><span onClick={() => clipushadd()}></span></div>
                    <Space wrap>
                        <Form style={{maxWidth:'210px'}} name="form" form={form2} >
                            <Form.Item key="search" name="search"><Input placeholder="Поиск" suffix={<SearchOutlined />} onChange={ props.onChange } /></Form.Item>
                        </Form>
                    </Space>
                </Col>
                <Col key="k2_clients" span={24} className="shadowscreen" >
                    <Table 
                        className="table-padding"
                        locale={{ emptyText: <Empty description="Список пуст" />}}
                        dataSource={obj.clientlist} 
                        scroll={{ x: 400 }} 
                        pagination={{ position: ['none', 'bottomLeft'] }}
                        rowKey="table1" 
                        columns={columns1}
                        onRow={(record, rowIndex) => {
                            return {
                            onClick: event => {clipush(record.id)}
                            };
                        }}
                    >
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;